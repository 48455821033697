const { getTimezone } = require('./dateTimeHelper');
const { systemSetUserTimezone } = require('./userHelper');

require('./bootstrap');
require('./imageHelper');
require('./videoHelper');
require('./fileHelper');
require('./scrollHelper');
require('./rateLimitHelper');
require('./urlHelper');
require('./inAppHelper');
require('./moment');
require('./priceHelper');
require('./numberHelper');
require('./inputHelper');
require('./dateTimeHelper');

const appUrl = window.location.origin;
const locale = process.env.MIX_APP_LOCALE;
const currentUserTimezone = document.querySelector('meta[name="timezone"]')?.getAttribute('content');

console.log('appUrl:', appUrl);
console.log('locale:', locale);
console.log('timezone:', getTimezone());
console.log('currentUserTimezone:', currentUserTimezone);

document.addEventListener('DOMContentLoaded', function (event) {
    forceInAppToUseBrowser();
});

window.addEventListener('load', function (event) {
    // if enable this need to handle the redirect after login
    // systemSetUserTimezone();
});